import React, { useEffect } from 'react'

function Player (props) {
  useEffect(() => {
    console.log(props)
    window
      .threekitPlayer({
        authToken: 'b56cdaa4-fae1-49a0-a2db-a222e95c9bb4',
        el: document.getElementById('player'),
        stageId: '',
        assetId: 'ca823b8b-13cf-498c-80e2-017bf32086ec',
        showConfigurator: false,
        showAR: false
      })
      .then(async player => {
        window.player = player
        window.configurator = await player.getConfigurator()
        await player.when('loaded')
        let loaded = props.loaded
        props.setLoaded();
      })
  }, [])
  return (
    <div>

      <div className='threekit-player' id='player'></div>
    </div>
  )
}

export default Player
