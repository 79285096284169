import React, { Component } from 'react'
import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import './index.css'
import Player from './components/Player'

import Texty from 'rc-texty'
import 'rc-texty/assets/index.css'
import QueueAnim from 'rc-queue-anim'
import chroma from 'chroma-js'
import { isMobile } from 'react-device-detect'

const layout = {
  default: [
    'q w e r t y u i o p',
    'a s d f g h j k l',
    '{shift} z x c v b n m {backspace}',
    '{space}'
  ],
  shift: [
    'Q W E R T Y U I O P',
    'A S D F G H J K L',
    '{shift} Z X C V B N M {backspace}',
    '{numbers} {space} {ent}'
  ],
  numbers: ['1 2 3', '4 5 6', '7 8 9', '{abc} 0 {backspace}']
}
const display = {
  '{ent}': 'return',
  '{escape}': 'esc ⎋',
  '{tab}': 'tab ⇥',
  '{backspace}': '⌫',
  '{capslock}': 'caps lock ⇪',
  '{shift}': '⇧',
  '{controlleft}': 'ctrl ⌃',
  '{controlright}': 'ctrl ⌃',
  '{altleft}': 'alt ⌥',
  '{altright}': 'alt ⌥',
  '{metaleft}': 'cmd ⌘',
  '{metaright}': 'cmd ⌘',
  '{abc}': 'ABC'
}

let loaded = false
class App extends Component {
  state = {
    layoutName: 'default',
    input: undefined,
    color1: '',
    color2: '',
    color3: '',
    show: false,
    colors: {},
    i: 1
  }

  stringToColour = function (str) {
    var hash = 0
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    var colour = '#'
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff
      colour += ('00' + value.toString(16)).substr(-2)
    }
    return colour
  }

  // getInputSetColors (input) {
  //   let length = input.split(' ').length
  //   if (length == 1) {
  //     this.setState({ color1: this.stringToColour(input.split(' ')[0]) })
  //     console.log(
  //       'color: ' +
  //         input.split(' ')[0] +
  //         this.stringToColour(input.split(' ')[0])
  //     )
  //   } else if (length == 2) {
  //     this.setState({ color2: this.stringToColour(input.split(' ')[1]) })

  //     console.log(
  //       'color: ' +
  //         input.split(' ')[1] +
  //         this.stringToColour(input.split(' ')[1])
  //     )
  //   } else if (length == 3) {
  //     this.setState({ color3: this.stringToColour(input.split(' ')[2]) })

  //     console.log(
  //       'color: ' +
  //         input.split(' ')[2] +
  //         this.stringToColour(input.split(' ')[2])
  //     )
  //   } else {
  //     return
  //   }

  //   window.configurator.setConfiguration({
  //     Color: this.state.color1,
  //     'Sole Color': this.state.color2,
  //     'Lace Color': this.state.color3
  //   })
  // }

  onChange = input => {
    console.log('Input changed', input)
    let length = input.split(' ').length
    console.log(length)

    this.setState({
      input: input
    })

    // this.getInputSetColors(input)
  }

  onKeyPress = button => {
    console.log('Button pressed', button)

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === '{shift}' || button === '{lock}') this.handleShift()
  }

  handleShift = () => {
    let layoutName = this.state.layoutName

    this.setState({
      layoutName: layoutName === 'default' ? 'shift' : 'default'
    })
  }

  onChangeInput = event => {
    let input = event.target.value

    console.log(input)

    this.setState(
      {
        input: input
      }, isMobile ? null : 
      () => {
        this.keyboard.setInput(input)
      } 
    )
  }

  setLoaded = () => {
    this.setState({ show: true })
  }

  hashMessage = async m => {
    const msgBuffer = new TextEncoder().encode(m)
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    console.log('The Buffer', hashArray)
    return hashArray
    //const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    //return hashHex;
  }

  componentToHex = c => {
    var hex = c.toString(16)
    return hex.length == 1 ? '0' + hex : hex
  }

  rgbToHex = (r, g, b) => {
    return (
      '#' +
      this.componentToHex(r) +
      this.componentToHex(g) +
      this.componentToHex(b)
    )
  }

  generate = async m => {
    let i = 1
    const trimmed = m.trim()
    const trimmedLowered = trimmed.toLowerCase()
    console.log('Hashing ', trimmedLowered)
    const hash = await this.hashMessage(trimmedLowered)
    const hashHex = hash.map(b => b.toString(16).padStart(2, '0')).join('')

    const configAttrs = [
      'Body Main Color', // Done
      'Side Mesh Color', //done
      'Back Mesh Color', // done
      'Back Detail Color', // done
      'Wrap Side Color', // Done
      'Sole Wrap Color', // Done
      'Sole Color', // done
      'Lace Color', // done
      'Interior Color'
    ]
    Array.from(Array(document.querySelector('#out').children.length).keys())
      .reverse()
      .map(e => {
        // document
        //   .querySelector('#out')
        //   .removeChild(document.querySelector('#out').children.item(e))
        console.log(e)
      })
    var interval = null
    const ret = {}
    let colorObj = {}
    configAttrs.map(v => {
      ret[v] = {}
      ret[v]['r'] = hash.shift() / 255
      ret[v]['g'] = hash.shift() / 255
      ret[v]['b'] = hash.shift() / 255

      const a = document.createElement('p')
      a.innerHTML = v
      a.style.color =
        'rgb(' +
        ret[v]['r'].toString() +
        ',' +
        ret[v]['g'].toString() +
        ',' +
        ret[v]['b'].toString() +
        ')'
      console.log(ret)

      console.log('COLORS')
      configAttrs.forEach(e => {
        if (ret[e]) {
          colorObj = {
            ...colorObj,
            [e]: chroma
              .scale([
                '#ffffff',
                this.rgbToHex(ret[e].r * 255, ret[e].b * 255, ret[e].g * 255)
              ])
              .mode('rgb')
              .colors(20)
          }
          window.configurator.setConfiguration({ [e]: ret[e] })
        } else {
          return undefined
        }
      })
      let configObj = {}

      i++
      // These are index - 1 becasue i starts at 1.
      configAttrs.forEach(e => {
        configObj[e] = colorObj[e]

        // console.log(e)

        console.log(colorObj)
      })
      window.configurator.setConfiguration(ret)
    })

    document.querySelector('#out').innerText = hashHex

    console.log('colors', ret)

    return ret
  }

  render () {
    return (
      <div>
        <div style={{ width: '100%' }}>
          <p style={{ fontSize: '50px' }}>{this.state.input}</p>
          <div id='out'></div>
          <Player loaded={this.state.show} setLoaded={() => this.setLoaded()} />
          <QueueAnim className='demo-content'>
            {this.state.show ? (
              <div
                key='keyboard-content'
                style={ isMobile ? null : {
                  position: 'fixed',
                  left: 0,
                  bottom: 0,
                  width: '100%'
                }}
              >
                <span style={{ margin: 'auto' }}>
                  <input
                    value={this.state.input}
                    placeholder={'Tap on the virtual keyboard or type to start'}
                    onChange={e => this.onChangeInput(e)}
                    style={{
                      width: '90%',
                      margin: '10px',
                      alignContent: 'center'
                    }}
                  />
                  <button
                    id='go'
                    onClick={() => this.generate(this.state.input)}
                  >
                    Go
                  </button>
                </span>
                <div>
                  {isMobile ? null : (
                    <Keyboard
                      keyboardRef={r => (this.keyboard = r)}
                      theme={'hg-theme-default myTheme1'}
                      layoutName={this.state.layoutName}
                      onChange={input => this.onChange(input)}
                      onKeyPress={button => this.onKeyPress(button)}
                      physicalKeyboardHighlight={true}
                      layout={layout}
                      display={display}
                    />
                  )}
                </div>
              </div>
            ) : null}
          </QueueAnim>
        </div>
      </div>
    )
  }
}

export default App
